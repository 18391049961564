<template>
	<div
		v-if="items_wallet.length > 0"
		class="mt-30 text-left flex-row"
	>
		<div class="flex-side mr-30">
			<div class="under-line pb-10 flex-row">
				<h3 class="flex-1">{{  program.title }}</h3>
			</div>

			<div>
				<ul>
					<li
						v-for="(currency, c_index) in currencies"
						:key="'currency_' + c_index"
						class="under-line ptb-20 cursor-pointer"
						:class="{ on: item_currency.coin_token_code == currency.coin_token_code }"
						@click="setCurrency(currency)"
					>{{ currency.label }}</li>
				</ul>
			</div>
		</div>

		<div
			class="flex-1"
		>
			<div class="under-line pb-10 flex-row">
				<h3 class="flex-1">{{ item_currency.label }}</h3>
			</div>

			<div
				v-if="item.coin_token_name"
			>
				<div class="flex-row ptb-30 under-line items-center">
					<div class="mr-10">
						<img
							:src="$request.upload_url(item_currency.coin_token_code)"
						>
					</div>
					<div class="flex-1 line-height-280 font-weight-500">{{ item_currency.label }}</div>
					<div class="flex-row items-center">

						<span class="size-px-20 font-weight-bold mr-10 color-primary">{{ item.holding_coin_quantity | toFixed(8) }}</span>
						<span class="size-px-20 font-weight-bold mr-10 color-primary">{{ item_currency.label }}</span>
						<div 
							class="flex-column gap-10"
							>
							<button
							class="btn-inline btn-primary radius-20 pa-13-30"
							@click="is_035 = !is_035"
						>입금</button>
						<button
							class="btn-inline btn-primary radius-20 pa-13-30"
							@click="toWidthrawal"
						>출금</button>
						</div>
						
					</div>
				</div>
				<div>
					<div class="pt-30">
						<div class="">
							<h4 class="size-px-16 color-333">입출금 이력</h4>
						</div>
						<div class="mt-20">
							<table
								v-if="items_history.length > 0"
								class="table"
							>
								<colgroup>
									<col width="180px">
									<col width="auto">
									<col width="120px">
								</colgroup>
								<thead>
								<tr>
									<th class="t-left">입출금 일시</th>
									<th class="t-left">거래ID</th>
									<th class="t-right">상태</th>
								</tr>
								</thead>
								<tbody>

									<template
										v-for="(history, index) in items_history"
									>
										<tr
											:key="'history_' + index"
											class="under-line-dashed"
										>
											<td class="under-line-dashed" style="text-align: left;">{{  history.tranmsndt }}</td>
											<td class="td-left under-line-dashed">
												<a
													:href="explorer + history.transaction_id"
													target="txid"
													class=""
												>{{  history.transaction_id | maxLength(100, '...')}}</a>
											</td>
											<td class="tb_ing_gray under-line-dashed" style="color:#dc505c">{{  history.tranmsn_state_name }}</td>
										</tr>
										<tr
											:key="'history2_' + index"
										>
											<td>{{  history.tranmsn_div_name }}</td>
											<td class="tb_ing_gray">
												<span class="cursor-pointer" @click="$bus.$emit('clipboard', history.withdrawal_wallet_address)"><v-icon small class="color-gray mr-10" >mdi-content-copy</v-icon>{{ history.withdrawal_wallet_address | maxLength(18, '...')}}({{  history.withdrawal_member_nickname }})</span>
												<v-icon class="mrl-10" small>mdi-arrow-right-thick</v-icon>
												<span class="cursor-pointer" @click="$bus.$emit('clipboard', history.receive_wallet_address)"><v-icon small class="color-gray mr-10" >mdi-content-copy</v-icon>{{ history.receive_wallet_address | maxLength(18, '...')}}({{  history.receive_member_nickname }})</span>
											</td>
											<td>{{  history.tranmsn_coin_token_quantity | makeComma(8)}}</td>
										</tr>
									</template>
								</tbody>
							</table>
							<div
								v-else
								class="none mt-30"
							>
								입출금 이력이 없습니다
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				v-else
				class="text-center mt-30 pa-30"
			>
				<div>
					<img
						:src="require('@/assets/image/web/intro/ico_char02.svg')"
						/>
				</div>
				<div class="text_guide pt-20">
					<h4>현재 생성된 지갑이 없습니다.</h4>
				</div>
				<div class="text_guide_desc pt-5">
					<p>지갑 생성을 하여 마피아 NFT카드를 구입하세요.</p>
				</div>

				<button
					class="btn-inline btn-blue radius-20 pa-10-20 mt-30"
					:class="{ disable: is_able }"
					:disabled="is_able"

					@click="is_0301 = true"
				>지갑 생성</button>
			</div>

			<mafia0301
				v-if="is_0301"
				:currencies="currencies"

				@click="postWallet"
				@cancel="is_0301 = false"
			></mafia0301>

			<mafia033
				v-if="is_033"
				:rules="rules"
				:wallet="item"
				:user="user"
				:blockchain_div_code="item.blockchain_div_code"

				@cancel="is_033 = false"
				@click="onPin"
			></mafia033>

			<mafia0331
				v-if="is_0331"
				:rules="rules"
				:wallet="item"
				:user="user"

				@cancel="is_0331 = false"
				@click="onPin"
			></mafia0331>

			<mafia035
				v-if="is_035"

				:user="user"
				:blockchain_div_code="item.blockchain_div_code"
				:token="item"

				@click="postDeposit"
			></mafia035>

			<PopupConfirm
				v-if="is_confirm"
				@click="toOtp"
				@cancel="is_confirm = false"
			>
				<template v-slot:title>이중인증 안내</template>
				<template
					v-slot:main-txt
				>이중인증 미등록 상태입니다</template>
				<template
					v-slot:sub-txt
				>Google OTP 설정 후 이용가능합니다.</template>
				<template
					v-slot:name-cancel
				>취소</template>
				<template
					v-slot:name-confirm
				>설정하기</template>
			</PopupConfirm>

			<PIN
				v-if="is_pin"
				:options="pin_option"
				:user="user"

				@click="onOtp"
				@cancel="is_pin = false"
				@goBack="is_pin = false"

				class="position-fixed-full bg-white z-index-layer"
			></PIN>

			<wallet_otp
				v-if="is_otp"
				:user="user"

				@click="postWithdrawal"
				@cancel="is_otp = false"
				@goBack="is_otp = false"

				class="position-fixed-full bg-white z-index-layer"
			></wallet_otp>
		</div>
	</div>

	<div
		v-else
		class="mt-30"
	>
		<div class="bg-none">
			<div class="text_guide pt-20">
				<h4>현재 생성된 지갑이 없습니다.</h4>
			</div>
			<div class="color-gray mt-10">
				<p>지갑 생성을 하여 마피아 NFT카드를 구입하세요.</p>
			</div>
			<div class="mt-30">
				<button class="btn-inline btn-primary radius-20 pa-10-20" @click="is_0301 = true">지갑 생성</button>
			</div>
		</div>

		<mafia0301
			v-if="is_0301"
			:user="user"
			:currencies="currencies"
			:wallet="item"

			@click="postWallet"
			@cancel="is_0301 = false"
		></mafia0301>
	</div>
</template>

<script>
import mafia0301 from "@/view/Wallet/mafia030-1";
import mafia035 from "@/view/Wallet/mafia035";
import Mafia033 from "@/view/Wallet/mafia033";
import mafia0331 from "@/view/Wallet/mafia033-1";

import PIN from "@/view/Auth/mafia007"
import wallet_otp from "@/view/Wallet/wallet_otp";
import PopupConfirm from "@/view/Layout/PopupConfirm";

export default {
	name: 'mafia30'
	, props: ['rules', 'user']
	, components: {PopupConfirm, wallet_otp, Mafia033, mafia0301, mafia035, PIN, mafia0331}
	, data: function(){
		return {
			program: {
				name: 'WALLET'
				, title: 'WALLET'
				, banner: true
			}
			, is_0301: false
			, currencies: [
				{ blockchain_div_code: 'BC00100001', name: 'KLAY', label: 'KLAY', coin_token_code: 'BC00200002'}
				, { blockchain_div_code: 'BC00100001', name: 'MAF KLAY', label: 'MAF',coin_token_code: 'BC00200003'}
				, { blockchain_div_code: 'BC00100002', name: 'ETH', label: 'ETH',coin_token_code: 'BC00200001'}
				// , { blockchain_div_code: 'BC00100002', name: 'MAF ETH', coin_token_code: 'BC00200004'}
				, { blockchain_div_code: 'BC00100002', name: 'REAP', label: 'REAP',coin_token_code: 'BC00200005'}
				, { blockchain_div_code: 'BC00100003', name: 'MATIC', label: 'MATIC',coin_token_code: 'BC00200006'}
			]
			, items_wallet: []
			, item: {
			}
			, item_withdrawal: {

			}
			, is_032: false
			, is_033: false
			, is_0331: false
			, is_035: false
			, is_pin: false
			, is_otp: false
			, pin_number: ''
			, pin_option: {
				pin_type: 'check'
			}
			, item_address: {

			}
			, is_confirm: false
			, wallet_type: 'eth'
			, blockchain_div_code: 'BC00100002'
			, items_history: [

			]
		}
	}

	,computed:{
		is_able: function(){
			let t = false

			if(this.items_wallet.length > 2){
				t = true
			}
			return t
		}
		, list_wallet: function(){
			return this.items_wallet.filter((item) => {

				if(item.coin_token_code == 'BC00200002'){
					item.label = 'klay'
					item.blockchain_div_code = 'BC00100001'
					this.currencies.filter( (currency) => {
						if(currency.blockchain_div_code == item.blockchain_div_code){
							currency.is_use = true
						}
					})
				}else if(item.coin_token_code == 'BC00200003'){
					item.label = 'maf'
					item.blockchain_div_code = 'BC00100001'

					this.currencies.filter( (currency) => {
						if(currency.blockchain_div_code == item.blockchain_div_code){
							currency.is_use = true
						}
					})
				}else if(item.coin_token_code == 'BC00200001'){
					item.label = 'eth'
					item.blockchain_div_code = 'BC00100002'

					this.currencies.filter( (currency) => {
						if(currency.blockchain_div_code == item.blockchain_div_code){
							currency.is_use = true
						}
					})
				}else if(item.coin_token_code == 'BC00200005'){
					item.label = 'reap'
					item.blockchain_div_code = 'BC00100002'

					this.currencies.filter( (currency) => {
						if(currency.blockchain_div_code == item.blockchain_div_code){
							currency.is_use = true
						}
					})
				}else if(item.coin_token_code == 'BC00200006'){
					item.label = 'matic'
					item.blockchain_div_code = 'BC00100003'

					this.currencies.filter( (currency) => {
						if(currency.blockchain_div_code == item.blockchain_div_code){
							currency.is_use = true
						}
					})
				}

				return item
			})
		}
		, list_currency: function(){
			let t = this.currencies

			return t
		}
		, explorer: function(){
			let t = 'https://baobab.scope.klaytn.com/tx/0x'
			let location = document.location.href
			if(location.indexOf(process.env.VUE_APP_DOMAIN) > -1){
				t = 'https://scope.klaytn.com/tx/0x'
			}

			return t
		}
		, item_currency: function(){
			let t = {

			}

			this.currencies.filter((item) => {
				if(item.coin_token_code == this.item.coin_token_code){
					t = item
				}
			})

			return t
		}
	}

	, methods: {
		getWalletList: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					,url: this.$api_url.api_path.get_wallet_list
					,data: {
						member_number: this.user.member_number
					}
					, type: true
				})

				if(result.success){
					this.items_wallet = result.data.wallet_list
					if(this.item.coin_token_code){
						result.data.wallet_list.filter( (item) => {
							if(this.item.coin_token_code == item.coin_token_code){
								this.item = item
							}
						})
					}else{
						this.item = this.items_wallet[0]
					}
				}else{
					throw result.message
				}
			}catch(e){
				this.$bus.$emit('notify',  { type: 'error', message: e})
				this.$log.console(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, postWallet: async function(currency){
			currency.user_id = this.user.id
			try{
				if(!currency.blockchain_div_code){
					throw '생성할 지갑을 선택하세요'
				}
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_wallet
					, data: {
						member_number: this.user.member_number
						, blockchain_div_code: currency.blockchain_div_code
					}
					, type: true
				})

				if(result.success){
					this.$bus.$emit('notify',  { type: 'success', message: result.message})
					this.is_0301 = false
					await this.getWalletList()
				}else{
					throw result.message
				}
			}catch(e){
				this.$bus.$emit('notify',  { type: 'error', message: e})
				this.$log.console(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		,onHistory: function(address){
			this.is_032 = true
			this.item_address = address
		}
		,postWithdrawal: async function(otp){
			let item = this.item_withdrawal
			try{
				item.currency_id = this.item.currency_id
				this.$bus.$emit('on', true)
				if(item.address == ''){
					this.$bus.$emit('notify', { type: 'error', message: '출금주소를 입력하세요'})
					return false
				}
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_withdrawal
					, data: {
						member_number: this.user.member_number
						, coin_token_code: this.item.coin_token_code
						, receive_wallet_address: this.item_withdrawal.address
						, receive_coin_quantity: this.item_withdrawal.amount
						, tranmsn_commis: this.item_withdrawal.tranmsn_commis
						, pinnumber: this.pin_number
						, gogle_otp_certfc_number: otp
					}
					, type: true
				})

				if(result.success){
					this.$bus.$emit('notify', { type: 'success', message: this.$language.common.success})
					await this.getWalletHistory()
					await this.getWalletList()
					this.is_033 = false
				}else{
					throw result.message
				}
			}catch (e) {
				this.$log.console(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
				this.is_pin = false
				this.is_otp = false
			}
		}

		,postDeposit: function(){

			this.is_035 = false
		}
		, onPin: function(item){
			this.item_withdrawal = item
			this.$bus.$emit('onPin', 'check')

			this.$bus.$on('pinCallback', (pin_type, pin_number)=> {
				if(pin_type == 'check'){
					this.pin_number = pin_number
					this.onOtp(pin_number)
				}
				this.$bus.$off('pinCallback')
			})
		}
		, onOtp: function(){
			this.$bus.$emit('onOtp', 'check')
			this.$bus.$on('otpCallBack', (otp_type, otp) => {
				if(otp_type == 'check'){
					this.postWithdrawal(otp)
				}
				this.$bus.$off('otpCallBack')
			})
		}
		, toOtp: function(){
			this.$bus.$emit('to', { name: 'mafia1113'})
		}
		, toWidthrawal: function(){
			if(this.user.gogle_otp_reg_fg == 'Y'){
				if(this.item.coin_token_code == 'BC00200003'){
					// this.is_0331 = true
					this.is_033 = true
				}else{
					this.is_033 = true
				}

			}else{
				this.is_confirm = true
			}
		}
		, to032: function(address){
			this.$emit('to', { name: 'mafia032', params: { id: address.coin_token_code }})
		}
		, setItem: function(address){
			this.item = address
		}
		, setCurrency: function(currency){
			this.items_wallet.filter((item) => {
				this.$log.console(item.coin_token_code, currency.coin_token_code)
				if(item.coin_token_code == currency.coin_token_code){
					this.item = item
					this.getWalletHistory()
				}
			})
		}

		, getWalletHistory: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					,url: this.$api_url.api_path.get_wallet_history
					,data: {
						member_number: this.user.member_number
						, coin_token_code: this.item.coin_token_code
						, page_number: this.$language.base.page_number
						, pagerecnum: this.$language.base.pagerecnum
					}
					,type: true
				})

				if(result.success){
					this.items_history = result.data.deptwidal_list
				}else{
					throw result.message
				}
			}catch(e){
				this.$bus.$emit('notify',  { type: 'error', message: e})
				this.$log.console(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
	}

	,async created() {
		this.$emit('onLoad', this.program)

		//await this.getCurrencies()

		await this.getWalletList()
		if(this.item.coin_token_code){
			await this.getWalletHistory()
		}
	}

}
</script>