<template>
    <PopupLayer
		v-if="is_view"
	>
        <template v-slot:body>
            <div v-if="wallet && wallet.address" class="ma-auto width-400 radius-20 pa-40-30 bg-white position-relative" style="box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);">
                <div class="flex-row justify-center mb-20">
                    <h4 class="">MAFIA WALLET 입금</h4>
                    <div class="popup-close">
                        <img class="cursor-pointer" @click="$emit('click')" :src="require('@/assets/image/web/notify_cartel/ico_close_bk.svg')" />
                    </div>
                </div>

                <div class="text-center ma-auto" style="width: 160px">
                    <!-- <div>
                        <img :src="$request.upload_url(wallet.coin_token_code)" />
                    </div> -->
                    <div v-if="wallet">
                        <div class="ma-auto qr_box">
                            <qr-code :text="wallet.address"></qr-code>
                        </div>
                        <div class="mt-20 flex-row justify-space-between items-center">
                            <p class="size-px-16" style="white-space: nowrap; text-overflow: ellipsis; overflow: hidden">{{ wallet.address }}</p>
                            <button class="btn_copy" @click="copy"><v-icon class="color-333">mdi-content-copy</v-icon></button>
                        </div>
                    </div>

                    <div v-else class="none">생성된 지갑이 없습니다</div>

                    <div class="mt-30">
                        <button class="btn-inline btn-primary radius-20 pa-10-30" @click="$emit('click')">확인</button>
                    </div>
                </div>
            </div>
        </template>
    </PopupLayer>
</template>

<script>
import Vue from 'vue';
import VueQRCodeComponent from 'vue-qrcode-component';
import PopupLayer from '@/view/Layout/PopupLayer';

Vue.component('qr-code', VueQRCodeComponent);

export default {
    name: 'mafia035',
    props: ['user', 'blockchain_div_code', 'token'],
    components: { PopupLayer },
    data: function () {
        return {
            wallet: {},
			is_view: false
        };
    },
    methods: {
        clipBoard: function (val) {
            const t = document.createElement('textarea');
            document.body.appendChild(t);

            t.value = val;
            this.$log.console('t.value', t.value);
            t.select();
            t.setSelectionRange(0, 9999);
            document.execCommand('copy');
            document.body.removeChild(t);
        },

        copy: function () {
            this.$log.console('this.wallet.address', this.wallet.address);
            this.clipBoard(this.wallet.address);
            this.$bus.$emit('notify', { type: 'success', message: '지갑주소가 복사되었습니다' });
        },

        getWalletAddress: async function () {
            try {
                const result = await this.$Request({
                    method: 'post',
                    url: this.$api_url.api_path.get_wallet_address,
                    data: {
                        member_number: this.user.member_number,
                        blockchain_div_code: this.blockchain_div_code,
                    },
                    type: true,
                });

                if (result.success) {
					this.is_view = true
                    this.$set(this.wallet, 'address', result.data.wallet_address);
                } else {
                    throw result.message;
                }
            } catch (e) {
                this.$log.console(e);
				this.$emit('click')
				this.$bus.$emit('notify', { type: 'error', message: e})
            }
        },
    },

    created() {
        this.getWalletAddress();
        this.$log.console('wallet', this.wallet);
    },
};
</script>

<style>
h4 {
    color: var(--mafia-Blue);
}
</style>
