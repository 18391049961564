<template>
	<div class="full_layer_wrap">
		<div class="full_layer_container">
			<div class="full_layer_wrap_inner">
				<article class="full_view">
					<div class="nft_card">
						<h3 class="pop_tit">MAFIA NFT 출금</h3>
						<div class="input_area">
							<div class="coin_logo">
								<img :src="require('@/assets/image/MAFI_60_g.png')" alt="마피코인">
							</div>
							<div class="bright_input pt-10 si">
								<select class="select-input">
									<option
										v-for="(b, index) in items_blockchain"
										:key="'b_' + index"
										:value="b.common_code"
									>{{ b.common_code_name}}</option>
								</select>
							</div>
							<div class="bright_input pt-10">
								<input
									v-model="item.address"
									type="text" placeholder="출금 주소 입력"
									:rules="[$rules.only_num_alpha(item, 'address')]"
									maxlength="255"
									@change="getTransCommis"
								>
							</div>
							<div class="input_data_result pt-10">
								<span class="result_name">ETH MAFI</span>
								<strong class="data">{{ item.tranmsn_commis }}<em class="pl-5 unit">MAFI</em></strong>
							</div>
							<div class="bright_input flex pt-10">
								<input
									v-model="item.amount"
									type="number" placeholder="출금 금액 입력"
									:rules="[$rules.max(item, 'amount', 25)]"
								>
								<button
									@click="max"
									class="btn_l btn_fill_blue ml-10 flex_shrink"
								>최대</button>
							</div>
							<div class="input_data_result pt-10">
								<span class="result_name">KLAY MAFI</span>
								<strong class="data">{{ item.tranmsn_commis }}<em class="pl-5 unit">MAFI</em></strong>
							</div>
							<div class="bright_input flex pt-10">
								<input
									v-model="item.amount"
									type="number" placeholder="출금 금액 입력"
									:rules="[$rules.max(item, 'amount', 25)]"
								>
								<button
									@click="max"
									class="btn_l btn_fill_blue ml-10 flex_shrink"
								>최대</button>
							</div>
							<div class="input_data_result pt-20">
								<span class="result_name">출금 가스비</span>
								<strong class="data">{{ item.tranmsn_commis }}<em class="pl-5 unit">KLAY</em></strong>
							</div>
						</div>
					</div>
					<div class="btn_wrap">
						<button class="pop_btn btn_fill_gray" @click="$emit('cancel')">취소</button>
						<button
							class="pop_btn btn_fill_blue" @click="postWithdrawal"
							:disabled="disabled"
						>출금신청</button>
					</div>
				</article>
			</div>
		</div>
		<div class="bg_overlay"></div>
	</div>
</template>

<script>

export default {
	name: 'mafia0331'
	, props: ['rules', 'wallet', 'user']
	, data: function(){
		return {
			item: {
				address: ''
				, amount: ''
				, tranmsn_commis: 0
			}
			, tranmsn_commis: 0
			, walletwallet_address: ''
			, items_blockchain: [
				{ common_code: 'B00100001', common_code_name: 'eth'}
				, { common_code: 'B00100002', common_code_name: 'klay'}
			]
		}
	}
	, computed: {
		disabled: function(){
			let t = true

			if(this.item.address && this.item.amount > 0){
				t = false
			}

			return t
		}
	}
	, methods: {
		postWithdrawal: function(){
			if(!this.item.address){
				this.$log.console('!this.item.address')
				return this.$bus.$emit('notify', { type: 'error', message: '출금주소를 입력하세요'})
			}else if(!this.item.amount) {
				this.$log.console('!this.item.amount')
				return this.$bus.$emit('notify', {type: 'error', message: '출금수량을 입력하세요'})
			}else if(Number(this.item.amount) <= 0){
				this.$log.console('Number(this.item.amount) <= 0')
				return this.$bus.$emit('notify', { type: 'error', message: '출금수량을 확인하세요'})
			}else if(this.item.amount > this.wallet.amount ) {
				this.$log.console('this.item.amount > this.wallet.amount')
				return this.$bus.$emit('notify', { type: 'error', message: '출금수량이 부족합니다'})
			}else{
				this.$log.console('click')
				this.$emit('click', this.item)
			}
		}
		, max: function(){
			this.item.amount = this.wallet.holding_coin_quantity
		}
		, getWalletAddress: async function(){
			try {
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_wallet_address
					, data: {
						member_number: this.user.member_number
						, blockchain_div_code: this.wallet.blockchain_div_code
					}
					, type: true
				})

				if(result.success){
					this.walletwallet_address = result.data.wallet_address
				}else{
					throw result.message
				}
			}catch (e) {
				this.$log.console(e)
			}
		}

		, getTransCommis: async function(){
			try{

				const result = await this.$Request({
					method: 'post'
					,url: this.$api_url.api_path.get_trans_commis
					,data: {
						member_number: this.user.member_number
						, blockchain_div_code: this.wallet.blockchain_div_code
						, coin_token_code: this.wallet.coin_token_code
						, receive_wallet_address: this.item.address
					}
					,type: true
				})

				if(result.success){
					this.item.tranmsn_commis = result.data.tranmsn_commis
				}else{
					throw result.message
				}
			}catch(e){
				this.$bus.$emit('notify',  { type: 'error', message: e})
				this.$log.console(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
	}

	, async created() {
		this.$log.console('wallet', this.wallet)

		//this.items_blockchain = await this.$bus.$emit('getCommonCode', 'BC001')
		this.$log.console('this.items_blockchain', this.items_blockchain)
	}
}

</script>

<style>
	.select-input {
		width: 100%;
		line-height: 48px;
		padding: 0 20px;
		font-size: 14px;
		color: var(--dark-Gray02);
		border: 1px solid var(--light-Gray02);
		border-radius: 50px;
		background: #fff;
		vertical-align: middle;
		box-sizing: border-box;
		outline: none;
		position: relative;
	}

	.si {
		position: relative
	}
	.si::after {
		content: '▼'; position: absolute; right: 18px; top: 28px; width: 0.5rem; height: .5rem; border-radius: .5rem;
	}
</style>