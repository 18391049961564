<template>
	<PopupLayer>
		<template
			v-slot:body
		>
			<div class="width-400 ma-auto">
				<div class="pa-10-20 bg-primary flex-row justify-space-between">
					<h4 class="color-white">지갑 생성</h4>
					<v-icon
						class="color-white"
						@click="$emit('cancel')"
					>mdi-close-circle</v-icon>
				</div>

				<div
					class="bg-white pa-20"
				>
					<ul>
						<li
							v-for="(currency, index) in currencies"
							:key="'currency_' + index"
							class="ptb-10"
						>
							<div
								v-if="!currency.is_use"
								class="bright_radio"
							>
								<input
									:value="currency.blockchain_div_code"
									type="radio" :id="'item_' + index" name="follower"
									@click="item = currency"
								>
								<label :for="'item_' + index">{{  currency.name  }}</label>
							</div>
							<div
								v-else
								class="bright_radio"
							>
								<input
									type="radio" :id="'item_' + index" name="follower"
									disabled
								>
								<label :for="'item_' + index">{{  currency.name  }} 보유중</label>
							</div>
						</li>
					</ul>

					<div class="">
						<button class="btn-inline btn-dark-gray radius-20  mr-10" @click="$emit('cancel')">취소</button>
						<button
							class="btn-inline btn-primary radius-20 "
							@click="$emit('click', item)"
							:disabled="!item.blockchain_div_code"
						>확인</button>
					</div>
				</div>
			</div>
		</template>
	</PopupLayer>
</template>

<script>
	import PopupLayer from "@/view/Layout/PopupLayer";
	export default {
		name: 'mafia0301'
		,
		components: {PopupLayer},
		props: ['currencies', 'wallet', 'user', 'blockchain_div_code']
		, data: function (){
			return {
				item: {

				}
			}
		}
		,methods: {
			getWalletAddress: async function(){
				try {
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_wallet_address
						, data: {
							member_number: this.user.member_number
							, blockchain_div_code: this.blockchain_div_code
						}
						, type: true
					})

					if(result.success){
						this.item = result.data
					}else{
						throw result.message
					}
				}catch (e) {
					this.$log.console(e)
				}
			}
		}

		, created() {
			this.$log.console(this.user)
			// this.getWalletAddress()
		}
	}
</script>